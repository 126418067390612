<template>
  <div class="elv-apply-project-form">
    <el-form ref="projectFormRef" :model="projectForm" :rules="rules" label-position="top">
      <el-form-item
        :label="props.model === 'add' && props.fromType !== 'entity' ? t('report.projectName') : t('report.entityName')"
        prop="name"
      >
        <el-input v-model="projectForm.name" minlength="1" maxlength="64" placeholder="" />
      </el-form-item>
      <el-form-item :label="t('report.entityLogo')">
        <ImageUpload v-model="projectForm.logo" />
      </el-form-item>
      <el-form-item :label="t('report.countryRegion')">
        <el-select
          v-model="projectForm.region"
          filterable
          popper-class="elv-apply-project-form-popper"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option
            v-for="(item, index) in countryOptions"
            :key="index"
            :label="locale === 'en' ? item.name_en : item.name_zh"
            :value="item.name_en"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('report.costBasisMethodology')" prop="costBasisMethodology">
        <el-select
          v-model="projectForm.costBasisMethodology"
          popper-class="elv-apply-project-form-popper"
          :disabled="true"
          :suffix-icon="SelectSuffixIcon"
        >
        </el-select>
      </el-form-item>
      <el-form-item :label="t('report.defaultCurrency')" prop="defaultCurrency">
        <el-select
          v-model="projectForm.defaultCurrency"
          popper-class="elv-apply-project-form-popper"
          :disabled="true"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option label="US Dollar" value="USD" />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('report.preferredCurrencyUnit')" prop="unit">
        <el-select
          v-model="projectForm.unit"
          :suffix-icon="SelectSuffixIcon"
          popper-class="elv-apply-project-form-popper"
        >
          <el-option
            v-for="(item, index) in unitOptions"
            :key="index"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <elv-button
      v-if="props.fromType === 'project' || (props.fromType === 'entity' && props.model !== 'add')"
      :width="props.model === 'edit' ? 156 : 159"
      height="44"
      round
      :loading="submitLoading"
      :disabled="disabledButton"
      class="elv-apply-trial-submit"
      @click="onSubmit"
      >{{ props.model === 'edit' ? t('button.saveChanges') : t('button.createProject') }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import countryList from '@/config/country'
import ProjectApi from '@/api/ProjectApi'
import { unitOptions } from '@/config/index'
import { isEmpty, sortBy } from 'lodash-es'
import { transformI18n } from '@/i18n/index'
import SelectSuffixIcon from './SelectSuffixIcon.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useReportStore } from '@/stores/modules/reports'
import { useProjectStore } from '@/stores/modules/project'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { ElMessage } from 'element-plus'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // add | edit
  },
  currentEntityData: {
    type: Object,
    default: () => {}
  },
  fromType: {
    type: String,
    default: 'project' // project | entity
  }
})

const { t, locale } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const reportStore = useReportStore()
const projectStore = useProjectStore()
const userGlobalStore = useUserGlobalStore()

const submitLoading = ref(false)
const projectFormRef = ref<FormInstance>()

const projectForm = reactive({
  name: '',
  logo: '',
  costBasisMethodology: 'FIFO',
  region: '',
  defaultCurrency: 'USD',
  unit: 'thousands'
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'name is required'
  },
  defaultCurrency: {
    required: true,
    trigger: 'blur',
    message: 'Default currency time is required'
  },
  costBasisMethodology: {
    required: true,
    trigger: 'blur',
    message: 'Cost basis methodology is required'
  },
  unit: {
    required: true,
    trigger: 'blur',
    message: 'Preferred currency unit is required'
  }
})

const countryOptions = computed(() => {
  if (locale.value === 'zh') return sortBy(countryList, [(o) => o.name_zh])
  return sortBy(countryList, [(o) => o.name_en])
})

const projectId = computed(() => {
  return Number(route.params.projectId)
})

const disabledButton = computed(() => {
  return !projectForm.name || !projectForm.unit
})

const onCreateProject = async () => {
  try {
    let res: any
    let entityId
    if (props.fromType === 'project') {
      res = await ProjectApi.createFreeProject(projectForm)
      entityId = res.data.entityList[0].entityId
    } else {
      res = await ProjectApi.createProjectEntity(projectId.value, projectForm)
      entityId = res.data.entityId
      projectStore.fetchProjectDetail(projectId.value)
    }
    ElMessage.success(props.fromType === 'project' ? t('message.createProjectSuccess') : t('message.addEntitySuccess'))
    await globalStore.fetchProjectList()
    router.push({ name: 'reports', params: { entityId } })
    await userGlobalStore.userInit()
    emitter.emit('onPushOpenCollapse', res.data.name)
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

const onUpdateProject = async () => {
  try {
    await ProjectApi.editEntityDetail(props.currentEntityData.entityId, projectForm)
    ElMessage.success(t('message.editSuccess'))
    submitLoading.value = false
    globalStore.fetchProjectList()
    reportStore.fetchEntityDetail(props.currentEntityData.entityId)
    if (props.fromType === 'entity') {
      projectStore.fetchEntityList(projectId.value)
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

const onSubmit = async () => {
  await projectFormRef.value?.validate(async (valid) => {
    if (valid) {
      submitLoading.value = true
      if (props.model === 'add') {
        await onCreateProject()
      } else {
        await onUpdateProject()
      }
    }
  })
}

watch(
  () => props.currentEntityData,
  (val) => {
    if (!isEmpty(props.currentEntityData)) {
      projectForm.name = val.name
      projectForm.logo = val.logo
      projectForm.costBasisMethodology = val.costBasisMethodology
      projectForm.region = val.region
      projectForm.defaultCurrency = val.defaultCurrency
      projectForm.unit = val.unit
    } else {
      projectForm.name = ''
      projectForm.logo = ''
      projectForm.costBasisMethodology = 'FIFO'
      projectForm.region = ''
      projectForm.defaultCurrency = 'USD'
      projectForm.unit = 'thousands'
    }
  },
  {
    immediate: true
  }
)

defineExpose({ onSubmit, disabledButton })
</script>

<style lang="scss">
.elv-apply-project-form {
  margin-top: 21px;
  padding-bottom: 20px;

  .el-input {
    width: 572px;
    height: 44px;
    border-radius: 4px;

    &.el-date-editor--date {
      .el-input__prefix {
        display: none;
      }
    }

    &.is-disabled {
      background: #f9fafb;

      .el-input__wrapper {
        background: #f9fafb;
        border: 1px solid #dde1e6;
        box-shadow: none;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

        .el-input__suffix {
          svg,
          i {
            width: 20px;
            height: 20px;
            fill: #d0d4d9;
          }
        }
      }

      .el-input__inner {
        color: #0e0f11;
        -webkit-text-fill-color: #0e0f11;
      }
    }
  }

  .el-textarea:not(.is-disabled) .el-textarea__inner {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &:focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-textarea__inner {
    padding: 8px 16px;
    width: 572px;
    min-height: 100px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #0e0f11;

    &:hover,
    &:focus {
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    color: #0e0f11;
  }

  .el-input__wrapper {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 4px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 1px 12px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    .el-input__suffix {
      svg,
      i {
        width: 20px;
        height: 20px;
        fill: #838d95;
      }
    }
  }

  .el-input:not(.is-disabled) .el-input__wrapper {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &.is_focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-form-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.is-error {
      .el-input__wrapper {
        background: #faeee6;
        border: 1px solid #7e4a15;
        box-shadow: none;
      }

      .el-input__inner {
        color: #7e4a15;
      }

      .el-form-item__error {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7e4a15;
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
    }
  }

  .el-select {
    .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-input__wrapper.is-focus {
    border: 1px solid #7596eb !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  }

  .elv-apply-trial-submit {
    margin-top: 24px;
  }
}

.elv-apply-project-date-shell-popper.el-popper {
  display: none;
}

.elv-apply-project-form-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
